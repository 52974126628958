import React from "react";
import { Input } from "./Input";
import { ErrorM } from "./ErrorM";

export interface FormTextFieldProps<T> {
  fieldName: keyof T;
  label: string;
  readOnly?: boolean;
  inputProps?: JSX.IntrinsicElements["input"];
}

const FormTextField = function<T>({
  fieldName,
  label,
  readOnly,
  inputProps,
}: FormTextFieldProps<T>) {
  return (
    <div className="FormBox FormSideBySide">
      <div className="FormLabel">
        <label>{label}</label>
      </div>
      <div className="FormField">
        <Input
          name={fieldName.toString()}
          className={readOnly ? "EditBox ViewOnly" : "EditBox"}
          readOnly={readOnly}
          {...inputProps}
        />
        <ErrorM name={fieldName.toString()} />
      </div>
    </div>
  );
};

export { FormTextField };
