import React from "react";
import Select from "react-select";
import { ValueType } from "react-select/src/types";

export type SelectOption = { value: string; label: string };

type DropdownProps = {
  options: { value: string; label: string }[];
  value: ValueType<SelectOption>;
  onChange(selectedOption: ValueType<SelectOption>): void;
  roundedRectangle?: boolean;
  small?: boolean;
  defaultMenuIsOpen?: boolean;
  isMulti?: boolean;
  readOnly?: boolean;
  dropdownProps?: any;
};

type Props = DropdownProps;

const Dropdown: React.FC<Props> = ({
  options,
  value,
  onChange,
  roundedRectangle,
  small,
  defaultMenuIsOpen,
  isMulti,
  readOnly,
  dropdownProps,
}) => {
  return (
    <Select
      className={
        roundedRectangle
          ? small
            ? "CustomSelect RoundedRect Small"
            : "CustomSelect RoundedRect"
          : small
          ? "CustomSelect Small"
          : "CustomSelect"
      }
      defaultMenuIsOpen={defaultMenuIsOpen}
      classNamePrefix={readOnly ? "CustomSelect Disabled" : "CustomSelect"}
      options={options}
      value={value}
      isDisabled={readOnly}
      onChange={onChange}
      isMulti={isMulti}
      menuPlacement="auto"
      {...dropdownProps}
    />
  );
};

export { Dropdown };
