import React from "react";
import classNames from "classnames";

type ButtonVariety = "full" | "small" | "blue" | "";

type ButtonColor = "blue";

type Button = JSX.IntrinsicElements["button"] & {
  color?: ButtonColor;
  variety?: ButtonVariety;
};

function varietyToClassname(v: ButtonVariety): string {
  switch (v) {
    case "full":
      return "BtnFull";
    case "small":
      return "BtnSmall";
    default:
      return "";
  }
}

function colorToClassname(c?: ButtonColor): string {
  switch (c) {
    case "blue":
      return "BtnBlue";
    default:
      return "";
  }
}

const Button: React.FC<Button> = ({
  variety = "",
  color,
  className,
  ...props
}) => (
  <button
    className={classNames(
      "Btn",
      colorToClassname(color),
      varietyToClassname(variety),
      className
    )}
    {...props}
  />
);

export default Button;
