import toPath from "lodash/toPath";
import clone from "lodash/clone";

function isObject(subj: any): subj is Object {
  return subj !== null && typeof subj === "object";
}

function isInteger(test: string): boolean {
  return Math.floor(Number(test)).toString() === test;
}

/**
 * Given an object and a path, traverse the object to find the value,
 * and return it.
 */
export function deepGet(obj: any, path: string | string[]) {
  const segments = toPath(path);
  let i = 0;
  let val = obj;
  for (; i < segments.length; i++) {
    if (val === undefined) {
      return val;
    }
    val = val[segments[i]];
  }
  return val;
}

/**
 * Given an object, a path and a new value, deeply set the value at the end of the path.
 *
 */
export function deepSet(obj: any, path: string | string[], newValue: any) {
  let segments = toPath(path);
  let res = clone(obj);
  let resVal = res;
  let i = 0;

  for (; i < segments.length - 1; i++) {
    const currentPath = segments[i];
    let currentObj = deepGet(obj, segments.slice(0, i + 1));

    if ((currentObj && isObject(currentObj)) || Array.isArray(currentObj)) {
      resVal = resVal[currentPath] = clone(currentObj);
    } else {
      const nextPath = segments[i + 1];
      resVal = resVal[currentPath] =
        isInteger(nextPath) && Number(nextPath) >= 0 ? [] : {};
    }
  }

  if ((i === 0 ? obj : resVal)[segments[i]] === newValue) {
    return obj;
  }

  if (newValue === undefined) {
    delete resVal[segments[i]];
  } else {
    resVal[segments[i]] = newValue;
  }

  if (i === 0 && newValue === undefined) {
    delete res[segments[i]];
  }

  return res;
}

export function setAllAsValue(
  obj: any,
  value: any,
  visited: any = new WeakMap(),
  response: any = {}
) {
  for (let k of Object.keys(obj)) {
    const val = obj[k];
    if (isObject(val)) {
      if (!visited.get(val)) {
        visited.set(val, true);
        response[k] = Array.isArray(val) ? [] : {};
        setAllAsValue(val, value, visited, response[k]);
      }
    } else {
      response[k] = value;
    }
  }

  return response;
}
