import React from "react";
import { FieldAttributes, useField } from "formik";
import classNames from "classnames";

function TextArea<T>(props: FieldAttributes<T>) {
  const [field, meta] = useField(props);
  const showError = meta.touched && meta.error;
  const { className: existingClasses, ...rest } = props;
  const className = classNames(existingClasses, { ErrorInput: showError });

  return <textarea {...(field as any)} {...rest} className={className} />;
}

export { TextArea };
