import React from "react";
import { TextArea } from "./TextArea";
import { ErrorM } from "./ErrorM";
import classNames from "classnames";

export interface FormTextAreaFieldProps<T> {
  fieldName: keyof T;
  label: string;
  readOnly?: boolean;
  small?: boolean;
  textAreaProps?: JSX.IntrinsicElements["textarea"] & { small?: boolean };
}

const FormTextAreaField = function<T>({
  fieldName,
  label,
  readOnly,
  small,
  textAreaProps,
}: FormTextAreaFieldProps<T>) {
  const s = small ?? textAreaProps?.small;
  const classnames = classNames(
    "TextArea",
    { ViewOnly: readOnly },
    { Small: s }
  );
  return (
    <div className="FormBox FormSideBySide">
      <div className="FormLabel">
        <label>{label}</label>
      </div>
      <div className="FormField">
        <TextArea
          name={fieldName.toString()}
          className={classnames}
          readOnly={readOnly}
          {...textAreaProps}
        />
        <ErrorM name={fieldName.toString()} />
      </div>
    </div>
  );
};

export { FormTextAreaField };
