import React from "react";
import { useFormikContext } from "formik";
import { Dropdown } from "./Dropdown";
import { deepGet } from "./deepObjUtils";
import { ErrorM } from "./ErrorM";

export type SelectOption = { value: any; label: string };

export interface FormSingleSelectFieldProps<T> {
  fieldName: keyof T & string;
  options: SelectOption[];
  label: string;
  readOnly?: boolean;
  dropdownProps?: any;
}

const FormSingleSelectField = function <T>(
  props: FormSingleSelectFieldProps<T>
) {
  const { fieldName, label, options, readOnly, dropdownProps } = props;
  const formik = useFormikContext<T>();

  const handleChange = (e: SelectOption) => {
    formik.setFieldValue(fieldName, e.value);
  };

  return (
    <div className="FormBox FormSideBySide">
      <div className="FormLabel">{label}</div>
      <div className="FormField">
        <Dropdown
          onChange={handleChange}
          options={options}
          value={options.find(
            o => o.value === deepGet(formik.values, fieldName)
          )}
          readOnly={readOnly}
          roundedRectangle
          dropdownProps={dropdownProps}
        />
        <ErrorM name={fieldName.toString()} />
      </div>
    </div>
  );
};

export { FormSingleSelectField };
